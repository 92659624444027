@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");
.header{
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
}
.pop-up-form-container{
  /* background-color: rgba(73, 69, 69,0.8); */
  backdrop-filter: blur(50px);
  height: 400px;
  position: absolute;
  z-index: 40;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  position: fixed;
}
.cross-icon{
  font-size: 30px;
  position: absolute;
  cursor: pointer;
  right: 25%;
}

.name-text-pop-up-form,.email-text-pop-up-form,.details-text-pop-up-form{
  width: 40%;
  height: 50px;
  border-radius: 5px;
  border: none;
  padding: 1%;
  margin: auto;
}
.details-text-pop-up-form{
  height: 100px;
  padding: 1%;
}
.pop-up-form-submit-button{
  width: 120px;
  margin: auto;
  margin-top: 10px;
  height: 40px;
  margin-top: 20px;
   margin-bottom: 20px;
   border-radius: 5px;
   border: none;
   cursor: pointer;
}
label{
  color: white;
}
.pop-up-form-main-div{
  margin-top: 5%;
  line-height: 3;
  background-color: rgba(0, 0, 0, 0.589);
  width: 50%;
  margin: auto;
  margin-top: 5%;
}
form{
  display: flex;
  flex-direction: column;
  /* background-color: rgb(73, 69, 69); */
}
.main-navbar-header {
  background: #0c5f7e;
  display: flex;
}
.logo-container-header {
  width: 20%;
  padding: 1%;
}

.navbar-text {
  color: white;
  width: 80%;
  padding: 1%;
  text-align: right;
}
.new-logo-image-header {
  display: none;
}
.additional-header {
  display: none;
}
.navbar-number-menu {
  color: #f2a94a;
}
.freequote-button {
  width: 25%;
  background-color: #4eaf3f;
  margin-top: 20px;
  text-align: center;
  float: right;
  border-radius: 5px;
}
.freequote-button-text {
  padding: 2%;
  width: 100%;
  color: white;
  cursor: pointer;
}
.navbar-options {
  width: 100%;
  background: #084156;
  height: 50px;
  font-family: "Nunito", sans-serif;
}

.sub-menu{
   display: flex;
   flex-direction: column;
   position: absolute;
   line-height: 2;
   display: none;
   left: 12%;
   width: 16%;
   padding: 1%;
   
   background-color: #084156;
}
.sub-menu-subservices{
  background-color: white;
  color: #084156;
}
.fa-Angle-Right-icon{
  text-align: right;
}
.sub-menu li{
  cursor: pointer;
  text-align:left;
  font-size: 12px;
  font-weight: 300;
}
.fa-Angle-Right{
  display: none;
}
.navbar-services-option:hover .sub-menu{
  display: block;
}

/* .navbar-options-link{
    display: flex;
} */
.navbar-options ul {
  display: flex;
  justify-content: space-around;
  color: white;
  width: 50%;
  height: auto;
  padding: 1%;
  text-transform: capitalize;
}
.navbar-options a {
  color: white;
  transition: 0.5s;
}
.navbar-options a:hover {
  color: #f2a94a;
}
.hamburger-menu {
  display: none;
}
.sub-menu .fa-Angle-Right-icon{
    text-align: right;
}
@media (max-width: 1084px) {
  .main-navbar-header {
    height: 90px;
  }
  .freequote-button {
    height: 40px;
    margin-top: 10px;
  }
  .freequote-button-text {
    font-size: inherit;
    padding-top: 5%;
  }
}
@media (min-width: 641px) and (max-width: 1007px) {
  .additional-header {
    display: flex;
  }
  .pop-up-form-main-div{
    width: 80%;
  }
  .cross-icon{
    right: 10%;
  }
  .navbar-options {
    display: none;
  }
  .main-navbar-header {
    display: none;
  }
  .sub-menu{
    display: flex;
    flex-direction: column;
    position: absolute;
    line-height: 2;
    display: none;
    left: 100%;
    top: 30%;
    width: 80%;
    padding: 5%;
    text-align:left;
    background-color: #084156;
  }
  .navbar-services-option:hover .sub-menu{
    display: block;
  }
  .fa-Angle-Right{
    display: block;
  }

  .phone-icon-font-awesome{
    font-size:xx-large;
  }
  .additional-navbar-number-menu-container {
    width: 50%;
    border-right: 1px solid white;
    background-color: orange;
    height: 70px;
    font-family: "Nunito", sans-serif;
    padding: 2%;
    color: white;
  }
  .additional-navbar-freequote-button{
    color: white;
    cursor: pointer;
  }
  .additional-navbar-freequote-button-container {
    width: 50%;
    padding: 3%;
    color: white;
    background: green;
    cursor: pointer;
    height: 70px;
    font-family: "Nunito", sans-serif;
    border-left: 1px solid white;
  }
  .main-navbar-options {
    height: 100px;
    background-color: #127092;
    width: 100%;

  }
  .new-logo-container-header {
    width: 65%;
    display: block;
  }
  .new-logo-image-header {
    width: 45%;
    padding: 2%;
    display: block;
    float: right;
  }
  .mobile-navbar-options {
    display: block;
    top: 92%;
    left: 0;
    height: auto;
    width: 30%;
    position: absolute;
    z-index: 99;
    background-color: #084156;
    transition: all 2s linear;
    transform-origin: top;
  }
  .services-option-main{
    display: flex;
  }
 .navbar-services-option{
  padding-left: 30%;
 }
  .mobile-navbar-options ul {
    height: 20rem;
    display: flex;
    color: white;
    flex-direction: column;
    width: 100%;
    align-items: inherit;
    background-color: #084156;
  }
  .mobile-navbar-options ul li:first-child {
    transition-delay: 0.2s;
  }

  .hamburger-menu {
    color: rgb(255, 255, 255);
    display: block;
    padding: 2%;
    position: absolute;
    font-size: 3.5rem;
    width: 20%;
  }
  .hamberger-menu-icon {
    color: white;
    width: 100%;
  }
  .fa-Angle-Down{
    display: none;
 }
}
@media (min-width: 360px) and (max-width: 640px) {
  .additional-header {
    display: flex;
  }
  .pop-up-form-main-div{
    width: 95%;
  }
  .cross-icon{
    right: 3%;
  }
  .additional-navbar-freequote-button{
    color: white;
  }
  .additional-navbar-freequote-button-container{
    cursor: pointer;
  }
  .navbar-options {
    display: none;
  }
  .main-navbar-header {
    display: none;
  }
  .phone-icon-font-awesome{
    font-size:xx-large;
    padding: 2%;
    color: white;
  }
  .fa-Angle-Down{
    display: none;
 }
  .mobile-sub-menu{
    display: flex;
    flex-direction: column;
    position: absolute;
    line-height: 2;
    display: none;
    left: 100%;
    top: 30%;
    width: 100%;
    padding: 5%;
    text-align:left;
    background-color: #084156;
  }
  .navbar-services-option:hover .sub-menu{
    display: block;
  }
  .additional-navbar-number-menu-container {
    width: 50%;
    border-right: 1px solid white;
    background-color: orange;
    height: 70px;
    font-family: "Nunito", sans-serif;
    padding: 2%;
    color: white;
  }
  .additional-navbar-freequote-button-container {
    width: 50%;
    padding: 4%;
    color: white;
    background: green;
    height: 70px;
    font-family: "Nunito", sans-serif;
    border-left: 1px solid white;
  }
  .main-navbar-options {
    height: 100px;
    background-color: #127092;
    width: 100%;
  }
  .services-option-main{
    display: flex;
  }
 .navbar-services-option{
  padding-left: 10%;
 }
 .fa-Angle-Right{
  display: block;
}
  .new-logo-container-header {
    width: 70%;
    display: block;
    padding: 1%;
  }
  .new-logo-image-header {
    width: 65%;
    padding: 3%;
    display: block;
    float: right;
  }
  .mobile-navbar-options {
    display: block;
    top: 100%;
    left: 0;
    height: auto;
    width: 50%;
    position: absolute;
    z-index: 99;
    background-color: #084156;
    transition: all 2s linear;
    transform-origin: top;
  }
  .mobile-navbar-options ul {
    height: 20rem;
    display: flex;
    color: white;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background-color: #084156;
    width: 100%;
  }
  .mobile-navbar-options ul li:first-child {
    transition-delay: 0.2s;
  }

  .hamburger-menu {
    color: rgb(255, 255, 255);
    display: block;
    padding: 2%;
    position: absolute;
    font-size: 3.5rem;
    width: 20%;
  }
  .hamberger-menu-icon {
    color: white;
    width: 100%;
  }
  .name-text-pop-up-form,.email-text-pop-up-form,.details-text-pop-up-form{
    width: 50%;
    height: 50px;
    border-radius: 5px;
    border: none;
    padding: 1%;
    margin: auto;
  }
  .details-text-pop-up-form{
    height: 80px;
  }
  .cross-icon{
    font-size: large;
  }
}
