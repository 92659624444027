.services-banner-main-container {
  background-size: cover;
  background-color: rgba(31, 29, 29, 0.8);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  height: 500px;
  margin-top: 140px;
}
.services-banner-heading {
  width: 49%;
  margin-left: 1%;
}
.services-banner-Heading-text {
  line-height: 1.5;
  color: white;
  padding: 4%;
}
.services-banner-Heading-text h1{
  color: white !important;
}
.services-banner-text {
  line-height: 2;
  color: white;
  padding: 4%;
}
.services-banner-button-container {
  justify-content: left;
}
.services-banner-button {
  margin-top: 20px;
  height: 40px;
  width: 150px;
  color: rgb(149, 207, 207);
  font-weight: 500;
  background-color: transparent;
  border: 1px solid rgb(106, 150, 150);
}
.services-banner-button:hover {
  color: white;
  background-color: rgb(106, 150, 150);
  border-color: white;
}
@media (min-width: 641px) and (max-width: 1100px) {
  .services-banner-main-container {
    height: 526px !important;
    margin-top: 170px;

  }
  .services-banner-heading {
    margin: auto !important;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .services-banner-main-container {
    height: 359px !important;
    margin-top: 170px;

  }
  .services-banner-heading {
    margin: auto !important;
  }
}
