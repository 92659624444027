.footer-main-container{
    background: #06577c;   
    margin-top: 100px;
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.sign-up-container{
 width: 48%;
 height: auto;
 margin-left: 1%;
 padding: 2%;
 background: #0470a2;
 color: white;
 margin-top: 30px;
 border-radius: 5px;
}
.text-box-container form{
    display: flex;
    width: 60%;
    flex-direction: row;
    margin: auto;
}
.input-field-Footer{
    margin-top: 8px;
    width: 90%;
    height: 40px;
    border-color:bisque;
    border-radius:5px 0px 0px 5px ;
    border: none;
    padding: 1%;
}
.submit-button{
    margin-top: 8px;
    width:30%;
    height: 40px;
    background: #f2a94a;
    cursor: pointer;
    font-size: 1em;
    color: white;
    border: none;
}
.sign-up-text{
    margin-top: 8px;
}
.request-an-estimate{
    margin-top: 8px;
    width: 80%;
    height: 40px;
    background: #06577c;  
    border: none;
    margin-left: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.customer-support{
    width:270px;
    margin-top: 8px;
    height: 40px;
    cursor: pointer;
    background: #06577c;  
    border: none;
    margin-left: 8px;
    color: white;
    border-radius: 5px;
}
.Navbar-Seo-Services{
    width: 97%;
    margin-top: 30px;
    border: 1px solid black;
    margin-left: 1%;
    display: flex;
    padding: 4%;
    height: auto;
    border-radius: 5px;
    background: #0470a2;
    border: none;
    color: white;
    line-height: 2;
}

.Heading-Container-footer1{
    width: 25%;
    text-align: center;
}
.Heading-Container-footer2{
    width: 25%;
    text-align: center;
}
.Heading-Container-footer3{
    width: 25%;
    text-align: center;
}
.Heading-Container-footer4{
    width: 25%;
    text-align: center;
}
.social-apps-navbar{
    width: 49%;
    margin-left: 1%;
    height:auto;
}
.copyrights-credits{
    width: 49%;
    margin-left: 1%;
    height: auto;
}
.social-apps-navbar-heading{
    text-align: center;
}
.icons{
    margin-left: 20px;
    font-size:xx-large;
    text-align: left;
    cursor: pointer;
}
.copyrights-credits{
    display: flex;
}
.copyrights-left-aligned{
    width: 50%;
    margin: 20px;
}
.copyrights-right-aligned{
    width: 50%;
}
.logo-image-container{
    width: 60%;
    margin-top: 25px;
}
@media(max-width:1099px){
   .sign-up-container{
    width: 100%;
   }
   
}
@media(max-width:812px){
    .Navbar-Seo-Services{
        flex-wrap: wrap;
    }
    .Heading-Container-footer1{
        width: 100%;
        margin-bottom: 10px;
    }
    .Heading-Container-footer2{
        width: 100%;
        margin-bottom: 10px;
    }
    .Heading-Container-footer3{
        width: 100%;
        margin-bottom: 10px;
    }
    .Heading-Container-footer4{
        width: 100%;
        margin-bottom: 10px;
    }
    .social-apps-navbar{
        width: 100%;
    }
    .copyrights-credits{
        flex-wrap: wrap;
    }
    .copyrights-left-aligned{
        width: 100%;
        text-align: center;
    }
    .copyrights-right-aligned{
        width: 100%;
    }
    .copyrights-credits{
        margin: auto;
    }
    .logo-image-container{
        margin-left: 20px;
    }
}
@media(max-width:426px){
    .input-field-Footer{
        width: 200px;
    }
}