.card-layout {
  margin-top: -50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
}
.main-card {
  display: flex;
  width: 100%;
  margin:50px auto;
  background-color:white;
  margin-top: 100px;
}
.main-card-image-container{
  width: 30%;
  height:400px;
}
.main-card-image{
  width: 100%;
}
.main-card-content{
  width:60%;
}
.bottom-cards {
  display: flex;
  justify-self: center;
  margin-left: 29.5px;
  flex-basis: calc(91.85%);
}

.bottom-card {
  flex-basis: calc(33.33% - 10px);
  margin-bottom: 20px;
  background-color: #efe0e0;
  padding: 50px;
}

h2 {
  margin-top: 10px;
}

p {
  margin-bottom: 0;
}

img {
  width: 100%;
  height: auto;
}

.bottom-card-image{
  border-radius: 50px;
}
.main-card-heading{
  color: blue;
}
.sub-card-heading{
  color: blue;
}
.card-body-paragraph{
  color: black;
  margin-bottom: 20px;
}
.services-main-heading{
  color: blue;
}
hr{
  color: black;
}