@media (min-width: 641px) and (max-width: 1100px) {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
    .main-container-about-us-card {
      margin-bottom: 0;
    }
    
  }
  @media (min-width: 360px) and (max-width: 640px) {
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 10px;
    }
    .main-container,
    .main-container-about-us-card {
      flex-direction: column;
    }
    .who-we-are-section,
    .image-section,
    .about-us-page-left-half-container,
    .about-us-page-right-half-container {
      width: 95% !important;
      margin: auto;
    }
    .about-us-page-right-half-text-container,
    .about-us-page-right-half-heading-container {
      text-align: center !important;
    }
    .about-us-page-image {
      width: 60%;
    }
    .about-us-page-image img {
      margin: 10px 0;
    }
    .about-us-page-image,
    .about-us-page-right-half-container {
      margin: auto;
    }
    .image-section,
    .about-us-page-image:nth-child(2) {
      padding-top: 50px;
    }
    .about-us-card2{
      flex-direction: column-reverse !important;
    }
  }