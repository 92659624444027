.mainContainer{
    margin-top: 5%;
}
/* Carousel.css */
.carousel{
  margin-top: 3%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 74%;
  background-color: rgba(0, 0, 0, 0.432); /* Black overlay initially */
  opacity: 1; /* Initially, make the overlay fully opaque */
  transition: opacity 0.3s ease; /* Add a smooth transition effect */
}

/* When hovering over the carousel item, make the overlay transparent */
.react-multi-carousel-item:hover .overlay {
  opacity: 0; /* Remove the overlay on hover */
}

/* Caption styles */
.caption p{
  font-size: x-large;
}
