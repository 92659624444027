
.about-page-heading-container{
    margin-top: 40px;
    color: #425b76;
    margin-bottom: 30px;
}
.about-page-banner-image{
    width: 100%;
    margin: auto;
}
.about-banner-image-1{
    height: 400px;
}
.about-page-main-container{
    margin-top: 200px;
}