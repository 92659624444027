.service-page-intro-main{
    margin-top: 80px;
}
.service-page-intro-text-1{
   color: #267caf;
}
.service-page-intro-heading-1{
    margin-top: 20px;
    color: #425b76;
}
.service-page-intro-heading-text{
    margin-top: 20px;
    width: 80%;
    margin-left: 10%;
    line-height: 2;
    color: rgb(82, 82, 78);
}