.new-service-page-services-card-container{
    display: flex;
    margin-top: 20px;
}
.new-service-page-services-card1,
.new-service-page-services-card2,
.new-service-page-services-card3,
.new-service-page-services-card4{
    width: 20%;
    margin-left: 3%;
}
.new-service-page-services-card1-logo{
    width: 60%;
    height: 140px;
}
.new-service-page-services-card1-heading-container{
    margin-top: 20px;
}
.new-service-page-services-card1-text-container{
    margin-top: 10px;
    line-height: 1.5;
    color: #425b76;
}
.new-service-page-services-main-card-logo-container{
    width: 20%;
    margin-left: 5%;
}
.new-service-page-services-main-card{
    margin-top: 100px;
    width: 100%;
    display: flex;
}
.new-service-page-services-main-card-content{
    width: 60%;
    line-height: 2;
    margin-left: 2%;
    margin-top: 20px;
}
.new-service-page-services-main-card-text-container{
    color: #425b76;
}
@media(min-width:768px) and (max-width:991px){
 .new-service-page-services-main-card-content{
    margin-top: -15px;
    width: 80%;
 }
 .new-service-page-services-main-card-logo-container{
    width: 15%;
    margin-left: 5px;
    margin-top: 10px;
 }
 .new-service-page-services-main-card-text-container{
    line-height: 1.5;

 }
 .new-service-page-services-card-container{
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
 }
.new-service-page-services-card1,
.new-service-page-services-card2,
.new-service-page-services-card3,
.new-service-page-services-card4{
    width: 30%;
    margin-left: 1%;
}
.new-service-page-services-card4{
    margin-top: 10px;
}
.new-service-page-services-card1-logo-container{
    width: 100%;
}
}
@media(min-width:481px) and (max-width:767px){
    .new-service-page-services-main-card-content{
        margin-top: -15px;
        width: 90%;
        margin: auto;
     }
     .new-service-page-services-main-card-logo-container{
        width: 93%;
        margin-top: 10px;
        height: 300px;
        margin-bottom: 30px;
     }
     .new-service-page-services-main-card-logo{
        width: 40%;
        justify-content: center;
     }
     .new-service-page-services-main-card-text-container{
        line-height: 1.5;
    
     }
     .new-service-page-services-card-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 40px;
     }
    .new-service-page-services-card1,
    .new-service-page-services-card2,
    .new-service-page-services-card3,
    .new-service-page-services-card4{
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }
    .new-service-page-services-card4{
        margin-top: 10px;
    }
    .new-service-page-services-card1-logo-container{
        width: 50%;
        align-items: center;
        margin-left: 25%;
    }
    .new-service-page-services-main-card{
        display: flex;
        flex-direction: column;
    }
    
}
@media(min-width:320px) and (max-width:480px){
    .new-service-page-services-main-card-content{
        width: 90%;
        margin-left: 30px;
     }
     .new-service-page-services-main-card-logo-container{
        width: 40%;
        margin-left: 100px;
        margin-top: 10px;
        margin-bottom: 20px;
     }
     .new-service-page-services-main-card-text-container{
        line-height: 1.5;
    
     }
     .new-service-page-services-card-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 40px;
     }
    .new-service-page-services-card1,
    .new-service-page-services-card2,
    .new-service-page-services-card3,
    .new-service-page-services-card4{
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }
    .new-service-page-services-card4{
        margin-top: 10px;
    }
    .new-service-page-services-card1-logo-container{
        width: 50%;
        align-items: center;
        margin-left: 25%;
    }
    .new-service-page-services-main-card{
        display: flex;
        flex-direction: column;
    }
    .new-service-page-services-main-card-logo{
        margin-bottom: -30px;
    }
}