.video-container {
  width: 100%;
  overflow:hidden;
  height: 400px;
  margin-top: 140px;
}
.main-video {
  width: 100%;
  object-fit: contain;
  position:relative;
  top: 0;
  left: 0;
}
.heading-overlay {
  position: absolute;
  z-index: 15;
  color: white !important;
  text-align: left;
  margin-top: 5%;
  padding: 2%;
  width: 50%;
  animation: moveUp linear 0.2s;
}
.heading-overlay h1 {
  font-size: 50px;

}
.heading-overlay h1 span{
  font-size: 50px;
  color: white;
}
@media (min-width: 641px) and (max-width: 1100px) {
  .heading-overlay {
    width: 80%;
    margin-top: 7%;
  }
  .heading-overlay h1 {
    font-size: 40px;
  }
  .heading-overlay h2 {
    font-size: 20px;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .video-container {
    overflow: none;
    height: 142px;
  }
  .heading-overlay {
    width: 80%;
    margin-top: 6%;
  }
  .heading-overlay h1 {
    font-size: 25px;
  }
  .heading-overlay h2 {
    font-size: 15px;
  }
}
@keyframes moveUp {
  0% {
    margin-bottom: -50px;
  }
  100% {
    margin-bottom: 0;
  }
}
