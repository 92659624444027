@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

li {
  list-style: none;

}

.logo-image{
  margin: auto;
  margin-left: 20px;
}

/* 
 

/* navbar style start  */

.main-nav {
  width: 100%;
  height: 6rem;
  display: grid;
  background: white;
  grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.logo {
  display: grid;
  /* background-color: #3b5998; */
  justify-content:center;
grid-column: 1/2;
  align-items:flex-start;
  width: 90%;
  height: 6em;
}

.menu-link {
  grid-column: 2/5;
  color: white

}

.menu-link ul {
  height: 6rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}

.social-media {
  grid-column: 5/6;
  margin-right: auto;
}

.social-media ul {
  height: 6rem;
  display: grid;
  grid-template-columns: 1fr repeat(3, 2fr);
  align-items: center;
  justify-content: flex-end;
}

.social-media ul li {
  text-align: right;
  margin-right: 10px;
}

.social-media ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */



.menu-link ul li {
  font-size: 20px;
  font-weight: bolder;
}

.menu-link ul li a {
  text-transform: capitalize;
  transition: 0.5s;
  color: #3c78bf;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgb(82, 82, 179);
  transition: 0.5s;
}

.social-media ul li {
  font-size: 1.8rem;
}

.social-media .hamburger-menu {
  display: none;
}


.facebook {
  color: black;
}

.facebook:hover {
  color: blue;
}
.instagram {
  color: black;
}
.instagram :hover{
  color: blue;
}
.youtube {
  color: black;
}
.youtube:hover {
  color: purple;
}
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }
  .log{
    width: 70%;
  }
  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color:white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
    background-color: white;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
  .hamberger-menu-icon{
    color: white; 
   }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
  .hamberger-menu-icon{
    color: white; 
   }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
.hamberger-menu-icon{
 /* color: black;  */
}
  /* hero section  */


}