.main-container-about-us-card{
    margin-top: 100px;
    display: flex;
    background-color:rgb(247, 243, 237);
    padding: 2%;
}
.about-us-page-left-half-container{
    width: 45%;
    margin-left: 3%;
}
.about-us-page-right-half-container{
    width: 40%;
    margin-left: 5%;
}
.about-us-page-right-half-heading-container{
    color: #425b76;
    text-align: left;
}
.about-us-page-right-half-text-container{
    text-align: left;
    margin-top: 20px;
    line-height: 1.5;
    color: rgb(82, 82, 78);
}