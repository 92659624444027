.main-container{
    display: flex;
}
.who-we-are-section{
    width: 50%;
    margin-left: 5%;
    text-align: left;
}
.who-we-are-heading{
    color: #425b76;
}
.main-container{
    margin-top: 50px;
}
.who-we-are-text{
    margin-top: 20px;
    line-height: 1.5;
}
.image-section{
    width: 48%;
}
.about-us-image{
    width: 30%;
    border-radius: 50%;
    border: 10px solid rgb(117, 110, 110);
}

