.contact-us-left-half-container {
  width: 40%;
  text-align: left;
  margin-left: 5%;
  margin-top: 50px;
}
.contact-us-uppertext-container{
  width: 85%;
}
.contact-us-heading-container {
  font-size: 25px;
  color: #425b76;
}
.contact-us-text-container {
  margin-top: 20px;
}
.contact-us-text-container h3 {
  color: #666666;
}
.contact-number {
  color: #267caf;
}
.contact-email-text-container {
  color: #666666;
  margin-top: 10px;
}
.email {
  color: #267caf;
}
.font-awesome-icons {
  margin-top: 20px;
}
.contact-us-icons {
  font-size: 30px;
}
.icon-1 {
  margin-left: 10px;
}
.contact-info {
  line-height: 2;
  margin-top: 20px;
}
.contact-us-main-container {
  width: 90%;
  margin: auto;
  display: flex;
  margin-top: 150px;
}
.contact-us-right-half-container {
  width: 50%;
}
.first-name {
  width: 48%;
  height: 40px;
  padding: 2%;
}
.last-name {
  width: 48%;
  margin-left: 20px;
  height: 40px;
  padding: 2%;
}
.name-container {
  margin-top: 60px;
  display: flex;
}
.same-input-fields {
  margin-top: 20px;
}
.contact-number-form {
  width: 100%;
  height: 40px;
  padding: 2%;
}
.contact-email-form {
  width: 100%;
  height: 40px;
  padding: 2%;
  margin-top: 20px;
}
.text-area-field {
  margin-top: 20px;
  padding: 2%;
  width: 100%;
  height: 80px;
  font-family: inherit;
  font-size: inherit;
}
.contact-form-submit-button {
  width: 100px;
  height: 40px;
  float: left;
  margin-top: 20px;
  color: white;
  background: #425b76;
  border: none;
  cursor: pointer;
}
.first-name,
.last-name,
.contact-number-form,
.contact-email-form {
  font-family: inherit;
  font-size: inherit;
}
@media (min-width: 641px) and (max-width: 1100px) {
  .name-container {
    margin-left: 0px;
  }
  .contact-us-uppertext-container{
    width: 85%;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .name-container {
    margin-left: 0;
  }
  .contact-us-uppertext-container{
    width: 100%;
  }
  .contact-us-main-container {
    flex-direction: column;
  }
  .contact-form-submit-button {
    margin-left: 0;
  }
  .contact-us-right-half-container{
    width: 100%;
  }
  .contact-us-left-half-container{
    width: 90%;
  }
} 
