.articles-heading{
    width: 100%;
    text-align: center;
    margin-top: 50px;
}


.articles-other-cards{
    display: flex;
    padding: 2%;
}
.articles-other-cards > div{
    border: 1px solid black;
    border-radius: 5px;
    margin: 10px;
    padding: 2%;
}
.articles-other-cards-heading-text-body{
    margin-top:20px;
}
.articles-other-cards-1{
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.607); 
  background-blend-mode: multiply;
  color: white;
  font-weight:bolder;
}
.articles-other-cards-2{
    background-size: cover;
    background-color:  rgb(0, 0, 0); ; 
  background-blend-mode: multiply;
  color: white;
  font-weight:bolder;
}

.articles-other-cards-3{
    background-size: cover;
    background-color:  rgba(0, 0, 0, 0.8); ; 
  background-blend-mode: multiply;
  color: white;
  font-weight:bolder;
}
.articles-heading-text{
    color: rgba(0, 0, 0, 0.782);
}

@media (max-width: 768px) {
    .articles-other-cards {
      flex-direction: column;
    }
  }
  

  @media (max-width: 500px) {
    .articles-heading-text{
        font-size: 30px;
    }
  }
  

  hr{
    border: none;
    height: 2px;
    background-color: rgb(64, 62, 62);
    font-weight: bold;
  }